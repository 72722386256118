

.spacer {
  height:100px;
}

.App > .App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  background: #222222;
  -webkit-animation: smoothEntrance 0.3s;
  animation: smoothEntrance 0.3s;

  .title {
    margin: 0;
    padding: 0.67em 0;
    font-size: calc(20px + 2vmin);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 576px) {
      padding: 0 0 0.33em;
    }
  }

  .menu {
    display: none;

    .list {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin:10px 0 0;
    }

    .list li {
      display: inline;
    }

    .list button.link {
      text-decoration: none;
      color: #9165c3;
      align-items: normal;
      background-color: rgba(0, 0, 0, 0);
      border-style: none;
      box-sizing: content-box;
      cursor: pointer;
      display: inline;
      font: inherit;
      height: auto;
      padding: 0;
      perspective-origin: 0 0;
      text-align: start;
      transform-origin: 0 0;
      width: auto;
      -moz-appearance: none;
      -webkit-logical-height: 1em;
      -webkit-logical-width: auto;

      &:hover {
        color: white;
      }
    }

    @media (min-width: 576px) {
      display: block;
    }
  }
}
