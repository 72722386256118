.App-header > .HamburgerDrawer {
  display: none;
  text-align: left;

  @media (max-width: 576px) {
    display: block;
  }

  .HamburgerDrawer-panel button.link {
    text-decoration: none;
    color: #9165c3;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    perspective-origin: 0 0;
    text-align: start;
    transform-origin: 0 0;
    width: auto;
    -moz-appearance: none;
    -webkit-logical-height: 1em;
    -webkit-logical-width: auto;

    &:hover {
      color: black;
    }
  }

  #HamburgerDrawer-toggle {
    margin: 15px;
  }

  .HamburgerDrawer-icon {
    padding: 15px;

    > span,
    > span:before,
    > span:after {
      background-color: #9165c3;
    }
  }

  > input:focus ~ .HamburgerDrawer-icon {
    outline: 0;
  }
}
