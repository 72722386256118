.contact {
    margin-top: 50px;
}

.contactSection {

    .link {
        text-decoration: none;
        color: #9165c3;
        font-size: 120px;
    }
}