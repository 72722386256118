.skillIcons {
    margin: 20px auto 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 16px;
    max-width: 600px;
    font-size: 72px;

    & > i {
        height:100px;
    }
}