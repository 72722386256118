.tetrisAnchor {
  height:75px;
}

.tetris {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .innerTetris {
    margin: 0 auto;
    width: 100%;
    max-width: 530px;

    .points {
      margin: 0 10px;
      display: flex;
      justify-content: space-between;

      > p,
      > h2 {
        align-self: flex-end;
        margin: 0;
      }
    }

    .boardSections {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    /* .heldPiece,
        .gameBoard,
        .pieceQueue {
            display:inline-block;
        } */

    .heldPiece,
    .gameBoard,
    .pieceQueue {
      margin-bottom: 10px;
    }

    .game-board,
    .piece-view {
      border-spacing: 0;
    }

    .gameOver {
      position: absolute;
      margin-top: 140px;
      background-color: white;
      color: #222222;
      padding: 20px;
      border-radius: 10px;
    }

    .gameBoard {
      .controllerButtons {
        height: 45px;
        margin-top: 5px;
        display: flex;
        flex-direction: row;

        .column {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .lightButton {
    background-color: white;
    width: 42px;
    border-style: outset;
    border-radius: 5px;

    &:hover {
      background-color: lightgray;
    }
  }
}

.game-block {
  margin: 0;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #ddd;
}
.piece-i {
  background-color: #483261;
}
.piece-j {
  background-color: #654688;
}
.piece-l {
  background-color: #74509c;
}
.piece-o {
  background-color: #9165c3;
}
.piece-s {
  background-color: #a783cf;
}
.piece-t {
  background-color: #b293d5;
}
.piece-z {
  background-color: #a783cf;
}
.piece-preview {
  background-color: #eee;
}
