body {
  font-family: "Century Gothic";
  min-width: 420px;
}

.App {
  background-color: #222222;
  min-height: 100vh;
  min-width: 335px;
  text-align: center;
  color: white;

  @media (min-width: 576px) {
    padding-top: calc(75px + 3vmin);
  }
  @media (max-width: 576px) {
    padding-top: calc(50px + 2vmin);
  }

  .thanks {
    padding-bottom: 20px;
  }
}

h2 {
  margin: 0;
  font-size: calc(15px + 2vmin);
}
